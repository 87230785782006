
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,

 } from '@ionic/vue';


import { defineComponent } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { LoginResult, ApiError, isApiError } from '@/lib/api-endpoints';
import MPassword from '@/components/MPassword.vue';
import { useStore } from '@/store';
import * as yup from 'yup';
import t from '@/translation';
import { showModal } from '@/lib/modal';
import { useForm } from 'vee-validate';

import { checkmark } from 'ionicons/icons';


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    //IonItem,
    //IonLabel,
    //IonDatetime,
    //IonSelectOption,


    //MInput,
    //MSelect,
    MPassword,
  },
  setup(){

    const store = useStore();
    const router = useRouter();

    const changePasswordSchema = yup.object({
      // eslint-disable-next-line @typescript-eslint/camelcase
      old_password: yup.string().required().min(6).label(t('Old Password')),
      password: yup.string().required().min(6).label(t('New Password')),
      passwordConfirmation: yup.string().required(t('Please confirm the password'))
       .oneOf([yup.ref('password')], t('Passwords must match')),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: changePasswordSchema,
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        const pwResponse: LoginResult | ApiError = await store.dispatch('changePassword', values);
        if (! isApiError(pwResponse)){
          await showModal(t("Password Changed"), t("Your password has been changed.  Please login with the new password."));
          router.push("/login");
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      } 
    }

    return {
      checkmark,

      onSubmitForm,
    }
  }
});
