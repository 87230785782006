
import { eye, eyeOff } from 'ionicons/icons';
import { ref, defineComponent } from '@vue/runtime-core'
import {
 IonItem,
 IonLabel,
 IonInput,
 IonIcon,
} from '@ionic/vue'
import { useField } from 'vee-validate';

export default defineComponent({
  props:{
    label: {type: String, required: true},
    name: {type: String, required: true},
  },
  inheritAttrs:false,
  components:{
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
  },
  methods:{
    emitValue(evt: Event): void {
      if (evt.target != null){
        const target = evt.target as HTMLInputElement;
        this.$emit('update:modelValue', target.value);
      }
    }
  },
  setup(props) {

    const { errorMessage, value: modelValue, handleChange} = useField (props.name);


    const showPassword = ref(false);
   return{
     errorMessage,
     modelValue,
     handleChange,
     showPassword,
     eye,
     eyeOff,
   };  

  },
})
