import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_m_password = _resolveComponent("m-password")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/home/settings",
                    text: _ctx.$t('Cancel')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _withModifiers(_ctx.onSubmitForm, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.checkmark
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Save')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Change Password')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode("form", {
            action: "#",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSubmitForm()), ["prevent"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.onSubmitForm()), ["enter"]))
          }, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_m_password, {
                  label: _ctx.$t('Old Password'),
                  name: "old_password"
                }, null, 8, ["label"]),
                _createVNode(_component_m_password, {
                  label: _ctx.$t('New Password'),
                  name: "password",
                  autocomplete: "new-password"
                }, null, 8, ["label"]),
                _createVNode(_component_m_password, {
                  label: _ctx.$t('Confirm New Password'),
                  name: "passwordConfirmation"
                }, null, 8, ["label"])
              ]),
              _: 1
            })
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}